import React, { useState } from 'react';
import axios from 'axios';
import IptvPlayer from './Iptvplayer';

import "../css/caja.css";

import imagenTv from '../img/icon_tv.png'

const PlaylistLoader = () => {
  const [playlistUrl, setPlaylistUrl]         = useState('https://iptv-org.github.io/iptv/countries/co.m3u');
  const [streams, setStreams]                 = useState([]);
  const [selectedStream, setSelectedStream]   = useState('');

  const loadPlaylist = async () => {
    try {
      const response = await axios.get(playlistUrl);
      const lines = response.data.split('\n');
      const parsedStreams = [];
      let currentStream = {};

      lines.forEach(line => {
        if (line.startsWith('#EXTINF:')) {
          const tvgNameMatch = line.match(/tvg-id="([^"]+)"/);
          const tvgLogoMatch = line.match(/tvg-logo="([^"]+)"/);

          currentStream = {
            id: parsedStreams.length,
            name: tvgNameMatch ? tvgNameMatch[1] : `Stream ${parsedStreams.length + 1}`,
            logo: tvgLogoMatch ? tvgLogoMatch[1] : null,
          };
        } else if (line.startsWith('http')) {
          currentStream.url = line;
          parsedStreams.push(currentStream);
          currentStream = {};
        }
      });

      setStreams(parsedStreams);
    } catch (error) {
      console.error('Error loading playlist:', error);
    }
  };
/*
  const loadPlaylistFromFile = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const content = event.target.result;
      parsePlaylist(content);
    };
    reader.onerror = (error) => {
      console.error('Error reading playlist file:', error);
    };
    reader.readAsText(file);
  };*/

  loadPlaylist();

  return (
    <div>
      <section className='input1'> 
        <input
        className='tamInput'
          type="text"
          placeholder="Enter playlist URL"
          value={playlistUrl}
          onChange={(e) => setPlaylistUrl(e.target.value)}
        />
        <button className='cargarIptv' onClick={loadPlaylist}>Cargar lista IPTV</button>
      </section>

      <section class="videoContainer"  id="myVideo"> 
        {selectedStream && <IptvPlayer url={selectedStream} />}
      </section>
      
      <section class="containerh2">
        <h2 class="tituloh2">Lista De Canales</h2>
      </section>

      <section class="containerCanales">
        {streams.map((stream) => (
           
            <div className='tv-box'>
              {stream.name }
              <img src= {stream.logo} onError={(e) => { e.target.src = imagenTv; }}  />
              
              
              <a href="#myVideo">
                <button onClick={() => setSelectedStream(stream.url)}> Ver</button>
              </a>
            </div>
         
        
            ))}

        </section>
      
      
    </div>
  );
};

export default PlaylistLoader;
