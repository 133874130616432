import PlaylistLoader from "./componentes/Playlistloader";

import './css/estilo.css'

import logo from './img/logo.png'

const Plantilla =() => {

 

    return (

        <>    

            <header class="header-container">
            <div class="content-logo">
                <img src={logo} alt="logo" class="logo" />
                <h1 class="titulo">Hnexux</h1>
            </div>
            
             </header>   

            

            <PlaylistLoader> </PlaylistLoader>

            <footer>
                <p>Política de Privacidad | Creado por: Sergio y Moisés | © 2023 - 2024 Todos los derechos reservados</p>
            </footer>

        </>
    )
  
}

export default Plantilla; 